
html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

#root {
    height: 100%;
}

.App {
  height:100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.router {
    height:100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.clickable {
    cursor: pointer;
}

.clickable:hover{
    background-color:#e8eef5;
}

.clickable-icon {
    cursor: pointer;
    color: #557393;
}

.clickable-icon:hover {
    color:#8fa8c3;
}

.color-warning {
    color:#d03e2a
}
.component-phonenumber-loader-container {
    width: 100%;
    height: 100%;
}

.component-phonenumber-loader-content {
    padding-top: 20px;
}


.component-container {
    padding: 10px;
    border: 1px solid #c7c7c7;
    box-shadow: 3px 3px 5px #94b3c2;
}

.component-header-decoration {
    font-weight:bolder;
    color:#557393;
}

.component-header-information {
    font-size: 0.875rem;
    font-weight: bolder;
    color: #7194ba;
}

.component-header-name-decoration {
    font-weight: bolder;
    color: #639fde;
}

.component-header-decoration-border {
    border-bottom: 1px solid #96b4d4;
}

.color-default {
    color: #557393;
}

.color-orange {
    color: #d7951f;
}

.color-red {
    color: #d71f1f;
}

.color-blue {
    color: #009aff;
}

.btn-toggle-more {
    padding: 0.15rem 0.35rem !important;
    font-size: 0.65rem !important;
}

.btn-toggle-groups {
    top: 7px !important;
    right: 10px !important;
}


.btn-cursor {
    cursor: pointer;
    color:#557393;
}

.btn-cursor:hover {
    color:#7fa5ce;
}

.service-object-specification-content{
    border:1px solid #94b3c2;
    margin:4px;
    padding:5px;
}

.table-unset-flex {
    flex:unset !important;
}

table th {
    color: #557393;
    text-align: left;
    border-bottom: 1px solid #557393;
    font-size: 12px;
    background-color:#fff;
}

table tr:nth-child(even) {
    background-color: #e8eef5;
}

.popover {
    box-shadow: 2px 3px 10px #94b3c2 !important;
    border: 1px solid #557393 !important;
}

.popover-size-4 {
    max-width:400px !important;
    width:400px !important;
}

.popover-size-5 {
    max-width: 500px !important;
    width: 500px !important;
}

.popover-size-6 {
    max-width: 600px !important;
    width: 600px !important;
}

.form-control-label {
    font-size: 10px;
    font-weight: bolder;
    color: #557393;
}

label.small {
    font-size: 0.875rem;
    font-weight: bolder;
    
    color: #7194ba;
}



.tooltip .tooltip-inner {
    background-color: #96b4d4; /* Set the desired background color */
    color: #ffffff; /* Set the desired text color */
}

.tooltip.bs-tether-element-attached-bottom .tooltip-arrow,
.tooltip.bs-tether-element-attached-top .tooltip-arrow {
    border-top-color: #96b4d4; /* Set the desired arrow color */
}

.tooltip.bs-tether-element-attached-left .tooltip-arrow,
.tooltip.bs-tether-element-attached-right .tooltip-arrow {
    border-left-color: #96b4d4; /* Set the desired arrow color */
}

